
.ngx-datatable.material.table-shadow {
    background: #FFF;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    /**
	 * Shared Styles
	 */
    /**
	 * Global Row Styles
	 */
    /**
	 * Header Styles
	 */
    /**
	 * Body Styles
	 */
    /**
	 * Footer Styles
	 */
}

.ngx-datatable.material.xs {
    font-size: 10px;
}

.ngx-datatable.material.sm {
    font-size: 12px;
}

.ngx-datatable.material.md {
    font-size: 14px;
}

.ngx-datatable.material.lg {
    font-size: 16px;
}

.ngx-datatable.material.table-striped .datatable-row-even {
    background: #f9f9f9;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #304FFE;
    color: #FFF;
}

    .ngx-datatable.material.single-selection .datatable-body-row.active:hover,
    .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
    .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
    .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
        background-color: #193AE4;
        color: #FFF;
    }

    .ngx-datatable.material.single-selection .datatable-body-row.active:focus,
    .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus,
    .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus,
    .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
        background-color: #2041EF;
        color: #FFF;
    }

.ngx-datatable.material.table-hover:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material.table-hover:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: #f5f5f5;
    /*transition-property: background;
        transition-duration: .3s;
        transition-timing-function: linear;*/
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
    background-color: #ddd;
}

.ngx-datatable.material.cell-selection.table-hover .datatable-body-cell:hover,
.ngx-datatable.material.cell-selection.table-hover .datatable-body-cell:hover .datatable-row-group {
    background-color: #f5f5f5;
    /*transition-property: background;
        transition-duration: .3s;
        transition-timing-function: linear;*/
}

.ngx-datatable.material.cell-selection .datatable-body-cell:focus,
.ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
    background-color: #ddd;
}

.ngx-datatable.material.cell-selection .datatable-body-cell.active,
.ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
    background-color: #304FFE;
    color: #FFF;
}

    .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover,
    .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
        background-color: #193AE4;
        color: #FFF;
    }

    .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus,
    .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
        background-color: #2041EF;
        color: #FFF;
    }

.ngx-datatable.material .empty-row {
    height: 50px;
    text-align: left;
    padding: .5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
}

.ngx-datatable.material .loading-row {
    text-align: left;
    padding: .5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
}

.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
    background-color: #FFF;
    background-position: 100% 0;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}

.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
    background-position: 0 0;
    background-color: #fff;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}



.ngx-datatable.material .datatable-header {
    border-bottom: 1px solid #ddd;
}


.ngx-datatable.material.colored-header .datatable-header {
    padding: .9rem 0;
    color: #fff;
    background-color: #027FF4;
}

.ngx-datatable.material.table .datatable-body .datatable-body-row,
.ngx-datatable.material.table-bordered .datatable-body .datatable-body-row {
    border-top: 1px solid #ddd;
}



.ngx-datatable.material.table-bordered .datatable-header .datatable-header-cell {
    padding: .9rem 1.2rem;
}


.ngx-datatable.material.full-header .datatable-header .datatable-header-cell {
    padding: .9rem 1.2rem;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: left;
    padding: .1rem 1.2rem;
    font-weight: 400;
    font-weight: bold;
    vertical-align: bottom;
}

    .ngx-datatable.material .datatable-header .datatable-header-cell.longpress {
        color: #027FF4;
        background-color: whitesmoke;
    }

    .ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
        border-right: none;
    }

.ngx-datatable.material .datatable-header .resize-handle {
    border-right: solid 1px #eee;
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
    background: #f5f5f5;
    padding: 10px;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: left;
    padding: .7rem 1.2rem;
    vertical-align: top;
    border-top: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

.ngx-datatable.material .datatable-body .progress-linear {
    display: block;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index:9999;
}

    .ngx-datatable.material .datatable-body .progress-linear .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 5px;
        -webkit-transform: translate(0, 0) scale(1, 1);
        transform: translate(0, 0) scale(1, 1);
        background-color: #aad1f9;
    }

        .ngx-datatable.material .datatable-body .progress-linear .container .bar {
            transition: all .2s linear;
            -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
            animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
            transition: -webkit-transform .2s linear;
            transition: transform .2s linear;
            background-color: #106cc8;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
        }

.ngx-datatable.material .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 14px;
    color: #777;
}

    .ngx-datatable.material .datatable-footer .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem;
    }

    .ngx-datatable.material .datatable-footer .datatable-pager {
        margin: 0 10px;
    }

        .ngx-datatable.material .datatable-footer .datatable-pager li {
            vertical-align: middle;
        }

            .ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
                color: rgba(0, 0, 0, 0.26) !important;
                background-color: transparent !important;
            }

            .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
                background-color: rgba(158, 158, 158, 0.2);
                font-weight: bold;
            }

        .ngx-datatable.material .datatable-footer .datatable-pager a {
            height: 22px;
            min-width: 24px;
            line-height: 22px;
            padding: 0 6px;
            border-radius: 3px;
            margin: 6px 3px;
            text-align: center;
            vertical-align: top;
            color: rgba(0, 0, 0, 0.54);
            text-decoration: none;
            vertical-align: bottom;
        }

            .ngx-datatable.material .datatable-footer .datatable-pager a:hover {
                color: rgba(0, 0, 0, 0.75);
                background-color: rgba(158, 158, 158, 0.2);
            }

        .ngx-datatable.material .datatable-footer .datatable-pager .icon-left,
        .ngx-datatable.material .datatable-footer .datatable-pager .icon-skip,
        .ngx-datatable.material .datatable-footer .datatable-pager .icon-right,
        .ngx-datatable.material .datatable-footer .datatable-pager .icon-prev {
            font-size: 20px;
            line-height: 20px;
            padding: 0 3px;
        }

/**
 * Checkboxes
**/
.datatable-checkbox {
    position: relative;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 0;
}

    .datatable-checkbox input[type='checkbox'] {
        position: relative;
        margin: 0 1rem 0 0;
        cursor: pointer;
        outline: none;
    }

        .datatable-checkbox input[type='checkbox']:before {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            content: "";
            position: absolute;
            left: 0;
            z-index: 1;
            width: 1rem;
            height: 1rem;
            border: 2px solid #f2f2f2;
        }

        .datatable-checkbox input[type='checkbox']:checked:before {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            height: .5rem;
            border-color: #009688;
            border-top-style: none;
            border-right-style: none;
        }

        .datatable-checkbox input[type='checkbox']:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 1rem;
            height: 1rem;
            background: #fff;
            cursor: pointer;
        }

/**
 * Progress bar animations
 */

@keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}
